import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";
import wpp from "../../assets/call_whats.png";
import tel from "../../assets/call_fone.png";

const Menu = () => {
  return (
    <nav id="menu">
      <div className="container">
        <div className="left">
          <figure>
            <img src={logo} alt="Logo" />
          </figure>
        </div>

        <div className="right">
          <figure>
            <a
              href="http://wa.me/+5527992872655?text=Gostaria%20de%20saber%20mais"
              target="_blank"
              rel="noreferrer"
            >
              <img src={wpp} alt="Whatsapp" />
            </a>
          </figure>

          <figure>
            <a href="tel:992872655">
              <img src={tel} alt="Telefone" />
            </a>
          </figure>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
