import React from "react";
import "./styles.css";

import cta from "../../assets/call_servicos.png";

const CTAServices = () => {
  return (
    <figure id="ctaServices">
      <a href="#gallery">
        <img src={cta} alt="Chamada de ação para os serviços" />
      </a>
    </figure>
  );
};

export default CTAServices;
