import React from "react";
import "./styles.css";

import cta from "../../assets/call_form.png";

const CTAForm = () => {
  return (
    <figure id="ctaForm">
      <a
        href={
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
            ? "#formMobile"
            : "#form"
        }
      >
        <img src={cta} alt="Chamada de ação para o Formulário" />
      </a>
    </figure>
  );
};

export default CTAForm;
