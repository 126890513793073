import "./styles.css";

import CTAForm from "../../components/CTAForm";

import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import card4 from "../../assets/card4.png";
import bloco from "../../assets/BLOCO4.png";
import blocoMobile from "../../assets/mobile/BLOCO4.png";

const Content = () => {
  return (
    <section id="content">
      <div className="content1">
        <div className="container">
          <h2 className="title">Nossos serviços</h2>

          <article className="gallery" id="gallery">
            <div className="card">
              <figure>
                <img src={card1} alt="Telefonia Móvel" />
              </figure>

              <CTAForm />
            </div>

            <div className="card">
              <figure>
                <img src={card2} alt="Banda Larga Móvel" />
              </figure>

              <CTAForm />
            </div>

            <div className="card">
              <figure>
                <img src={card3} alt="NET" />
              </figure>

              <CTAForm />
            </div>

            <div className="card">
              <figure>
                <img src={card4} alt="EMBRATEL" />
              </figure>

              <CTAForm />
            </div>
          </article>
        </div>
      </div>

      <div className="content2">
        <div className="container">
          <figure>
            <img src={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? blocoMobile
                : bloco
            } alt="Rastreio de frotas" />
          </figure>

          <CTAForm />
        </div>
      </div>
    </section>
  );
};

export default Content;
