import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Menu from "./components/Menu";
import LGPD from "./components/LGPD";
import Footer from "./components/Footer";

import { 
  Header,
  About,
  Content,
  Success
} from "./pages";

function App() {
  return (
    <div className="App">
      <LGPD />
      <Menu />
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <About />
              <Content />
            </>
          }>      
          </Route>
          <Route path="/sucesso" element={<Success />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
