import React from "react";
import "./styles.css";

import CTAServices from "../../components/CTAServices";
import Form from "../../components/Form";

const Header = () => {
  return (
    <header id="header">
      <div className="header1">
        <div className="container">
          <div className="left">
            <h2 className="title">
              Soluções em conectividade para a sua empresa.
            </h2>

            <CTAServices />
          </div>

          <div className="right">
            <Form />
          </div>
        </div>
      </div>

      <div className="mobile" id="formMobile">
        <div className="container">
          <Form />
        </div>
      </div>
    </header>
  );
};

export default Header;
