import React from "react";
import "./styles.css";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2 className="title">Sobre nós</h2>
        <p className="text">
          Somos a empresa Conceito Soluções, autorizada da Claro pessoa
          jurídica. Nós estamos localizados em Linhares no estado do Espírito
          Santo, onde atendemos fisicamente às empresa do nosso estado e também
          a todo o Brasil de forma remota.
        </p>
      </div>
    </section>
  );
};

export default About;
