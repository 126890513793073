import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import Enviar from "../../assets/enviar.png";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbyO8PaXelOQL00tChtrQ1FtKxDvExEmQ5dfXFp9jc6ePKXkHKwidWEO7T9obWD5xH2R/exec";

  const phoneMask = (e) => {
    if (e.target.value.length < 10) {
      return alert("Número inválido");
    } else if (e.target.value.length === 10) {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{4})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    } else {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{5})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit-image").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <h2 className="form-title">Solicite um orçamento</h2>
      <h3 className="form-subtitle">
        Preencha o formulário, entraremos em contato.
      </h3>
      <div className="form-div1">
        <label htmlFor="name">
          Nome Completo: <span>*</span>
        </label>
        <input type="text" id="name" name="nome" required />

        <div className="row">
          <div className="top">
            <label htmlFor="tel">
              Telefone: <span>*</span>
            </label>

            <label htmlFor="tel">
              Whatsapp: <span>*</span>
            </label>
          </div>

          <div className="bottom">
            <input
              type="tel"
              id="tel"
              name="telefone"
              onBlur={phoneMask}
              minLength="10"
              maxLength="11"
              required
            />

            <input
              type="tel"
              id="tel"
              name="whatsapp"
              onBlur={phoneMask}
              minLength="10"
              maxLength="11"
              required
            />
          </div>
        </div>

        <label htmlFor="email">
          E-mail: <span>*</span>
        </label>
        <input type="email" id="email" name="email" required />
        <label htmlFor="cnpj">
          CNPJ: <span>*</span>
        </label>
        <input type="text" id="cnpj" name="cnpj" required />
        <label htmlFor="servico">
          Escolha o serviço: <span>*</span>
        </label>
        <select id="servico" name="servico" required>
          <option value=""></option>
          <option value="telefonia-móvel">Telefonia Móvel</option>
          <option value="banda-larga-móvel">Banda Larga Móvel</option>
          <option value="net">NET</option>
          <option value="embratel">EMBRATEL</option>
          <option value="m2m">M2M</option>
          <option value="outra-opção">Outra opção</option>
        </select>
      </div>

      <div className="form-div2">
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
            name="CONTATO COMERCIAL"
            value="Quero"
            defaultChecked
          />
          <label htmlFor="check">Aceito receber contato comercial</label> <br />
          <button type="submit" id="submit">
            <figure>
              <img src={Enviar} alt="Enviar" id="submit-image" height={50} />
            </figure>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
